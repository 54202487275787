
import './App.css';
import { TextForm } from './pages/TextForm';

function App() {
  return (
    <>   
      <TextForm />
    </>
  );
}

export default App;
